import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const PAGE_NAME = "About Us"
const AboutPage = props => {
  return (
    <Layout pageName={"about"} headerTitle={PAGE_NAME}>
      <SEO title={PAGE_NAME} />
      <section id="about-us" className="container">
	  
	  
        <div className="row">
          <div className="col-sm-6">
		  <br /><br /><br />
         	<h2>Vision</h2>
            <p>
              AJVDC has a vision of eastern Congo living in peace, with sustainable socio-economic and technological development, and of seeing the natural resources of the region benefit the entire population without distinction.
            </p>
			
			
          </div>
        <div className="col-sm-6">
           <h2>
              <br />
            </h2>
            <Image imageKey={"image51"} />
          </div>
        </div>
		
		
		
		<div className="row">
          <div className="col-sm-12">
			
			<h2>Objectives</h2>
            <p>   
				AJVDC works towards improving the lives of vulnerable communities – especially disadvantaged youth and female victims of the horrors of war, via three main avenues :
				</p>
            <ul>
				<li>
					The promotion of entrepreneurship among women and the youth; 
				</li>
				<li>
					Improving access to basic social services like water, health and education; and
				</li>
				<li>
					Civic engagement of youth for peace, the protection of the environment and sustainable development. 
				</li>
            </ul>
			<br />
			<h2>Background</h2>
            <p>
              AJVDC (Association de Jeunes Visionnaires pour le Développement du
              Congo) it is a Youth Volunteer Peace Group, located in Eastern
              Congo, legal registered in Democratic Republic of Congo, North
              Kivu Province, Goma city, N° 529/COORP/04/EDDNK/2017
            </p>

            <p>
              AJVDC is a youth civic engagement framework, formation initiative
              and exchange framework that aims to promote youth entrepreneurship
              and peace, reconciliation and community resiliency in Eastern of
              Congo.
            </p>

            <p>
              Investment in creating income generating opportunities for
              Congolese people is one of the most effective ways to lift
              citizens out of the cycle of poverty, offering tremendous
              potential for increasing stability, and opening doors to improved
              health and education.
            </p>

            <p>
              Eastern of Congo has suffered heavy losses due to more than a decade of war and conflict and other atrocities (poverty, youth unemployment, hunger, sexual violence, lack of education, and many more). The conflicts displaced 8 million people; killed almost 6 million citizens (UNOCHA 2014 reports) and, eroded trust within and between communities.
            </p>

            <p>
              Youth were at the core of the conflict, either through recruitment
              into armed groups or as victims of social destabilization, causing
              a high number of children and youth to become combatants.
            </p>

            <p>
              During this post-conflict period and in the peacebuilding and
              community resiliency processes, a particular accent could be
              putted on the meaningful participation and sustainable involvement
              of young people in shared decisions in matters which affect their
              lives and those of their community, including planning,
              decision-making and program delivery.
            </p>
            <p>
              AJVDC youth group is all about youth engagement via participation in
              volunteerism, leading to positive youth development, and a sense of inclusion. 
            </p>
          </div>
        </div>


        <div className="row">
          <div className="col-sm-6">
            <h2>
              <br /><br /><br />
            </h2>
            <Image imageKey={"image52"} />
          </div>
          <div className="col-sm-6">
            <h2>Mission</h2>
			<p>
				Mobilising the efforts of each and every person to attain the objectives of ecologically sustainable development (ESD) in the DRC remains the AJVDC’s prime mission. Established by the United Nations and modified by the DRC, there were previously eight Millenial development goals in the DRC. However, since 2015 the goals have been expanded to 17 including almost all sectors and aspects of human and global development. 
				<br />
				The AJVDC places great emphasis on the role of young people in bringing about the completion of these goals in the DRC. Youth (the under 30 demographic) constitutes 65 % of the DRC population, and the AJVDC works to engage the young in all aspects of planning and initiatives aimed at sustainable development in the DRC. To be effective, active participation of the young will greatly help in accelerating ESD objectives by 2030.  
			</p>
           </div>
        </div>
		
		
        <div className="row">
          <div className="col-sm-12">
            <h2>Future goals</h2>
            <p>
              In addition to the above, it is AJVDC's ultimate goal to extend these activites and programs across the entirity of the North Kivu region.
            </p>
			<br />
			<h2>AJVDC’s leadership</h2>
            <p>
              AJVDC is made up of a strong team of visionaries, including both boys and girls and
              with a board and advisory council composed of men and women with the experience to guide AJVDC into the future.
            </p>
          </div>
          <div className="col-sm-6">
            <h3>Board Members</h3>
            <ul>
              <li>Mr. Placide Mudahama , President of Board</li>
              <li>Mrs Darlose Kavira</li>
              <li>Mr. Paulin Bachisima</li>
              <li>Pastor Olivier Kalegamire</li>
              <li>Mr. Prosper Hamuli</li>
              <li>Mrs. Christinne ZAWADI</li>
              <li>Mr. Leon Simweragi</li>
              <li>Mr. Yanik T Beya, Ambassador</li>
            </ul>
          </div>
          <div className="col-sm-6">
            <h3>Staff Members</h3>
            <ul>
              <li>Leon Simweragi : Chief Executive Officer, Coordonnator</li>
              <li>Mrs Christinne BASHONGA : Financial assistant</li>
              <li>Mr Dieudonne MULINDWA : Administrative Assistant</li>
              <li>
                Dr. Adim KALALA : Youth and Health, Youth leadership officer
              </li>
              <li>Mr Clement BONGANO: Community services officer</li>
              <li>
                Mrs Judith ZAWADI : Assistante au Programme (Jeunesse et
                protection de l’environnement)
              </li>
              <li>Mr. Timothy BASHONGA: Cultural exchange</li>
              <li>Mr. Bertin KALIMBIRO : Youth entrepreneurship</li>
              <li>Mr Justin MUTABESHA : Youth capacity development</li>
              <li>Mr Henri OBEDI: Youth and peacebuilding</li>
              <li>Mr Abeba ARUSI : Street children protection</li>
            </ul>
          </div>
        </div>
		
		
      </section>
    </Layout>
  )
}

export default AboutPage
